<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">Forgot</h1>
            </div>
          </template>

          <v-alert dismissible prominent type="error" v-if="error">
            {{ error }}
          </v-alert>
          <v-alert dismissible prominent type="success" v-if="!loading && !error && successMessage">
            {{ successMessage }}
          </v-alert>

          <v-card-text class="text-center">
            <v-form
              ref="form"
              method="post"
              class="form-auth"
              autocomplete="off"
              @submit.prevent="onSubmit"
            >
              <v-text-field
                v-model="email"
                color="secondary"
                label="Email..."
                prepend-icon="mdi-email"
                :rules="emailRules"
              />

              <pages-btn
                type="submit"
                large
                color=""
                depressed
                class="v-btn--text success--text"
              >
                Let's Go
              </pages-btn>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PagesForgot",

  components: {
    PagesBtn: () => import("./components/Btn"),
  },

  data: () => ({
    success: "",
    error: "",
    loading: false,

    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /\S+@\S+\.\S+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (!!v && v.length >= 8) || "Password must be more than 8 characters",
    ],
  }),

  created() {
    if (this.$auth.user()) {
      this.$router.push("/");
      return;
    }
  },

  computed: {
    ...mapState({
      successMessage: (state) => state.authModule.forgot.message,
    }),
  },

  methods: {
    onSubmit() {
      this.error = "";
      this.loading = true;

      if (!this.$refs.form.validate()) {
        return;
      }

      let data = {
        email: this.email,
      };
      this.$store
        .dispatch("authModule/forgot", data)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.response?.data?.message ?? "Unknown error";
        });
    },
  },
};
</script>
