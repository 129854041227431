<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">Reset</h1>
            </div>
          </template>

          <v-alert dismissible prominent type="error" v-if="error" class="mt-5">
            {{ error }}
          </v-alert>

          <v-alert
            prominent
            type="success"
            v-if="!error && !loading && successMessage"
            class="mt-5"
          >
            {{ successMessage }}
          </v-alert>

          <pages-btn
            type="submit"
            large
            color=""
            depressed
            :to="{ name: 'Login' }"
            class="v-btn--text success--text"
            v-if="!error && !loading && successMessage"
          >
            Go to Login
          </pages-btn>

          <v-form
            ref="form"
            method="post"
            class="form-auth"
            autocomplete="off"
            @submit.prevent="onSubmit"
            v-if="!successMessage"
          >
            <v-text-field
              v-model="password"
              type="password"
              color="secondary"
              label="Password..."
              :rules="passwordRules"
              prepend-icon="mdi-lock-outline"
            />

            <v-text-field
              v-model="passwordConfirmation"
              type="password"
              class="mb-8"
              color="secondary"
              label="Password confirmation..."
              :rules="confirmPasswordRules"
              prepend-icon="mdi-lock-outline"
            />

            <pages-btn
              type="submit"
              large
              color=""
              depressed
              class="v-btn--text success--text"
            >
              Reset
            </pages-btn>
          </v-form>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PagesReset",

  components: {
    PagesBtn: () => import("./components/Btn"),
  },

  data: () => ({
    error: "",
    loading: false,

    token: "",
    userId: null,
    password: "",
    passwordConfirmation: "",

    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (!!v && v.length >= 8) || "Password must be more than 8 characters",
    ],
  }),

  computed: {
    ...mapState({
      successMessage: (state) => state.authModule.reset.message,
    }),
    confirmPasswordRules() {
      let v = this.passwordConfirmation;
      const rules = [
        !!v || "Confirmation password is required",
        (!!v && v.length >= 8) || "Confirmation password must be more than 8 characters",
        this.password === v || "Password must match.",
      ];
      return rules;
    },
  },

  created() {
    if (this.$auth.user()) {
      this.$router.push("/");
      return;
    }

    let query = this.$route.query;
    this.token = query.token ?? "";
    if (!this.token) {
      this.error = "Wrong token";
    }

    this.userId = query.id ?? null;
    if (!this.userId) {
      this.error = "Wrong user";
    }
  },

  methods: {
    onSubmit() {
      this.error = "";

      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.token) {
        this.error = "Wrong token";
        return;
      }
      if (!this.userId) {
        this.error = "Wrong user";
        return;
      }
      this.loading = true;

      let data = {
        token: this.token,
        user_id: this.userId,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      };

      this.$store
        .dispatch("authModule/reset", data)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;

          let data = e.response.data;
          if (data.errors) {
            this.error = Object.values(data.errors).join(" ");
          } else {
            this.error = data.message ?? "Unknown error";
          }
        });
    },
  },
};
</script>
