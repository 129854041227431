<template>
  <v-container id="register" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">Register</h1>
            </div>
          </template>

          <v-alert dismissible prominent type="error" v-if="error" class="mt-5">
            {{ error }}
          </v-alert>

          <v-alert
            prominent
            type="success"
            v-if="!error && !loading && successMessage"
            class="mt-5"
          >
            {{ successMessage }}
          </v-alert>

          <pages-btn
            type="submit"
            large
            color=""
            depressed
            :to="{ name: 'Login' }"
            class="v-btn--text success--text"
            v-if="!error && !loading && successMessage"
          >
            Go to Login
          </pages-btn>

          <v-card-text class="text-center">
            <v-form
              ref="form"
              method="post"
              class="form-auth"
              autocomplete="off"
              @submit.prevent="onSubmit"
              lazy-validation
              v-if="!successMessage"
            >
              <v-text-field
                color="secondary"
                v-model="name"
                label="First Name..."
                :rules="nameRules"
                prepend-icon="mdi-face"
              />

              <v-text-field
                v-model="email"
                color="secondary"
                label="Email..."
                :rules="emailRules"
                prepend-icon="mdi-email"
              />

              <v-text-field
                v-model="password"
                type="password"
                color="secondary"
                label="Password..."
                :rules="passwordRules"
                prepend-icon="mdi-lock-outline"
              />

              <v-text-field
                v-model="passwordConfirmation"
                type="password"
                class="mb-8"
                color="secondary"
                label="Password confirmation..."
                :rules="confirmPasswordRules"
                prepend-icon="mdi-lock-outline"
              />

              <v-checkbox v-model="agree" color="secondary">
                <template v-slot:label>
                  <span class="text-no-wrap">I agree to the&nbsp;</span>

                  <a class="secondary--text ml-6 ml-sm-0" href="#">
                    terms and conditions </a
                  >.
                </template>
              </v-checkbox>

              <pages-btn type="submit" color="success"> Get Started </pages-btn>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PagesRegister",

  components: {
    PagesBtn: () => import("./components/Btn"),
    PagesHeading: () => import("./components/Heading"),
  },

  created() {
    if (this.$auth.user()) {
      this.$router.push("/");
    }
  },
  data: () => ({
    error: "",
    loading: false,
    successMessage: "",

    agree: true,
    password: "",
    passwordConfirmation: "",

    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (!!v && v.length <= 20) || "Name must be less than 20 characters",
      (v) => /^[a-zA-Z0-9-]+$/.test(v) || "Only letters, digits and -",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /\S+@\S+\.\S+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (!!v && v.length >= 8) || "Password must be more than 8 characters",
    ],
  }),
  computed: {
    confirmPasswordRules() {
      let v = this.passwordConfirmation;
      const rules = [
        !!v || "Confirmation password is required",
        (!!v && v.length >= 8) || "Confirmation password must be more than 8 characters",
        this.password === v || "Password must match.",
      ];
      return rules;
    },
  },
  methods: {
    onSubmit() {
      this.error = "";

      this.hasError = false;
      if (!this.$refs.form.validate()) {
        return;
      }

      let data = {
        name: this.name,
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      };

      this.$auth
        .register({
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data: data,
          redirect: false,
        })
        .then((response) => {
          if (response.data?.message) {
            this.successMessage = response.data?.message;
          } else {
            this.error = "Unknown error";
          }
        })
        .catch((error) => {
          this.hasError = true;
          let data = error.response.data;
          if (data.error && data.error != "Unauthorized") {
            this.error = data.error;
          } else if (typeof JSON.parse(data) === "object") {
            let errorsRaw = JSON.parse(data);
            this.error = Object.values(errorsRaw).join(" ", errorsRaw);
          } else {
            this.error = "Wrong login or password";
          }
        });
    },
  },
};
</script>

<style lang="sass">
#register
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
</style>
