<template>
<a class="item" @click="logout">
    <i class="sign out icon"></i>
</a>
</template>


<script>
export default {
  name: "PagesLogout",
  created(){
    this.$auth.logout()
    this.$router.push({ path: '/' })
  }
}
</script>
