<template>
  <v-container id="home" class="fill-height justify-center" tag="section">
    <section>
      <v-layout column wrap class="my-5" align-center>
        <v-flex xs12 sm4 class="my-3">
          <div class="text-xs-center">
            <h2 class="headline">The best way to have a profit</h2>
            <span class="display-1"> No more restrictions, no more limits </span>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-flex xs12 md4>
                <v-card class="elevation-0 transparent">
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-xs-center">Reach the world</div>
                  </v-card-title>
                  <v-card-text>
                    Effectively convert all types and sources of traffic
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card class="elevation-0 transparent">
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Fast feedback</div>
                  </v-card-title>
                  <v-card-text>
                    Time is important, we don't want you to waste it. Here you can get a
                    massive feedback in minutes.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card class="elevation-0 transparent">
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-xs-center">Convenient Dashboard</div>
                  </v-card-title>
                  <v-card-text>
                    Reports for every taste. Monitor your results with maximum
                    convenience.
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>

        <v-flex xs12>
          <v-container grid-list-xl>
            <base-material-card
              color="success"
              icon="mdi-clipboard-text"
              inline
              title="Rating"
              class="px-5 py-3 mb-5"
              v-if="item"
            >
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">&nbsp;</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in rating" :key="item.name">
                      <td class="text-xs-right">{{ item.position }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.points }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </base-material-card>
          </v-container>
        </v-flex>
      </v-layout>
    </section>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PagesHome",

  components: {
    PagesHeading: () => import("./components/Heading"),
    PagesPlanCard: () => import("./components/PlanCard"),
  },
  created() {
    this.$store.dispatch("rating/getRating");
  },
  computed: {
    ...mapState({
      rating: (state) => state.rating.rating,
    }),
  },
};
</script>
