<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">Login</h1>
            </div>
          </template>

          <v-alert dismissible prominent type="error" v-if="error">
            {{ error }}
          </v-alert>

          <v-card-text class="text-center">
            <v-form
              ref="form"
              method="post"
              class="form-auth"
              autocomplete="off"
              @submit.prevent="onSubmit"
            >
              <v-text-field
                v-model="email"
                color="secondary"
                label="Email..."
                prepend-icon="mdi-email"
                :rules="emailRules"
              />

              <v-text-field
                v-model="password"
                type="password"
                class="mb-8"
                color="secondary"
                label="Password..."
                prepend-icon="mdi-lock-outline"
                :rules="passwordRules"
              />

              <p>
                <router-link
                  :to="{ name: 'Forgot' }"
                  class="secondary--text ml-6 ml-sm-0"
                >
                  Remind password
                </router-link>
              </p>

              <pages-btn
                type="submit"
                large
                color=""
                depressed
                class="v-btn--text success--text"
              >
                Let's Go
              </pages-btn>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PagesLogin",

  components: {
    PagesBtn: () => import("./components/Btn"),
  },

  data: () => ({
    error: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /\S+@\S+\.\S+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (!!v && v.length >= 8) || "Password must be more than 8 characters",
    ],
  }),

  created() {
    if (this.$auth.user()) {
      this.$router.push("/");
      return;
    }
  },
  methods: {
    onSubmit() {
      this.error = "";

      if (!this.$refs.form.validate()) {
        return;
      }

      this.$auth
        .login({
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data: {
            email: this.email,
            password: this.password,
          },
          rememberMe: true,
          fetchUser: true,
        })
        .then(
          () => {
            this.$router.push("/");
          },
          (error) => {
            let data = error.response.data;
            if (data.error && data.error != "Unauthorized") {
              this.error = data.error;
            } else {
              this.error = "Wrong login or password";
            }
          }
        );
    },
  },
};
</script>
