<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">Verify</h1>
            </div>
          </template>

          <v-alert dismissible prominent type="error" v-if="error" class="mt-5">
            {{ error }}
          </v-alert>

          <v-alert
            prominent
            type="success"
            v-if="!error && !loading && successMessage"
            class="mt-5"
          >
            {{ successMessage }}
          </v-alert>

          <pages-btn
            type="submit"
            large
            color=""
            depressed
            :to="{ name: 'Login' }"
            class="v-btn--text success--text"
            v-if="!error && !loading && successMessage"
          >
            Go to Login
          </pages-btn>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PagesVerify",

  components: {
    PagesBtn: () => import("./components/Btn"),
  },

  data: () => ({
    error: "",
    successMessage: "",
    loading: false,
  }),

  created() {
    if (this.$auth.user()) {
      this.$router.push("/");
      return;
    }
    if (!this.$route.query.token) {
      this.error = "Wrong token";
      return;
    }

    if (!this.$route.query.id) {
      this.error = "Wrong user";
      return;
    }
    this.verifyRequest()
  },
  methods: {
    verifyRequest() {
      this.loading = true;
      let data = {
        token: this.$route.query.token,
        user_id: this.$route.query.id,
      };

      this.$store
        .dispatch("authModule/verify", data)
        .then(() => {
          this.loading = false;
          let successMessage = this.$store.state.authModule?.verify?.message ?? "";
          if (successMessage) {
            this.successMessage = successMessage;
          }
        })
        .catch((e) => {
          this.loading = false;

          let data = e.response.data;
          if (data.errors) {
            this.error = Object.values(data.errors).join(" ");
          } else {
            this.error = data.message ?? "Unknown error";
          }
        });
    },
  },
};
</script>
